<template>
  <div>
    <v-card class="ma-1 pa-1 h-100">
      <v-card-title class="pa-1">
        <span>{{ $t("blankTemplate.index") }}</span>
        <v-spacer></v-spacer>
        <v-btn color="#6ac82d" x-small dark fab @click="newBlank()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>

      <v-data-table
        dense
        fixed-header
        :loading-text="$t('loadingText')"
        :no-data-text="$t('noDataText')"
        :height="screenHeight"
        :loading="loading"
        :headers="headers"
        :items="items"
        class="ma-1"
        style="border: 1px solid #aaa;"
        single-expand
        item-key="id"
        :server-items-length="server_items_length"
        item-text="template_name"
        :footer-props="{
          itemsPerPageOptions: [20, 50, 100, -1],
          itemsPerPageAllText:$t('itemsPerPageAllText'),
          itemsPerPageText: $t('itemsPerPageText'),
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right'
        }"
        :options.sync="dataTableOptions"
        :disable-pagination="true"
        disable-sort
        @update:page="updatePage"
        @update:items-per-page="updatePerPage"
      >
        <template v-slot:item.id="{ item }">
          <span
            style="white-space:normal;display:block;cursor:pointer"
            @click="$router.push('/blank-templates/edit/' + item.id)"
          >{{items.map(function(x) {return x.id; }).indexOf(item.id) + 1}}</span>
        </template>
        <template v-slot:item.blank_name="{ item }">
          <span
            @click="$router.push('/blank-templates/edit/' + item.id)"
            style="white-space:normal;display:block;cursor:pointer"
            v-if="item"
          >{{ item.blank_name}}</span>
        </template>
        <template v-slot:item.description="{ item }">
          <span
            @click="$router.push('/blank-templates/edit/' + item.id)"
            style="white-space:normal;display:block;cursor:pointer"
            v-if="item.description">{{ item.description }}</span>
        </template>
        <template v-slot:item.file_type="{ item }">
          <span v-if="item.file_type===0"><v-icon color="blue">mdi-file-word-outline</v-icon>Word</span>
          <span v-else><v-icon color="green">mdi-file-excel-outline</v-icon>Excel</span>
        </template>
        <template v-slot:item.is_active="{ item }">
          <span v-if="item.is_active === 1"><v-icon color="green" large>mdi-toggle-switch</v-icon></span>
          <span v-else><v-icon color="gray" large>mdi-toggle-switch-off-outline</v-icon></span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn color="blue" small text @click="editItem(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn color="blue" small text @click="downloadTemplate(item.file_name)" >
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <v-btn color="red" small text @click="deleteItem(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="blankDialog" @keyup.native.enter="save" @keydown.esc="blankDialog = false" persistent
              max-width="600px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <span class="headline">{{ $t("blankTemplate.index") }}</span>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined x-small fab class @click="blankDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="dialogForm">
            <v-row>
              <v-col cols="12">
                <label for>{{ $t("name") }}</label>
                <v-text-field v-model="form.blank_name"
                              hide-details="auto"
                              dense
                              outlined>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <label for>{{ $t("carPurchase.comment") }}</label>
                <v-textarea v-model="form.description"
                            hide-details="auto"
                            dense
                            rows="2"
                            outlined>
                </v-textarea>
              </v-col>
              <v-col cols="12" style="min-width: 100px; max-width: 100%" class="flex-grow-1 flex-shrink-0">
                <label for>{{ $t("blankTemplate.file") }}</label>
                <v-file-input
                  v-model="file"

                  :rules="[
                    (v) => {
                      let allowedExtensions = /(\.docx)$/i || /(\.xlsx)$/i ;
                      let error = false;
                      (v) => {
                        if (!allowedExtensions.exec(file.name)) {
                          error = true;
                        }
                      };
                      return !error || $t('requiredformat');
                    },
                    (v) => (v.size < 5242880) || $t('requiredsize5'),
                    (v) => !!v || $t('input.required'),
                  ]"
                  outlined
                  dense
                  prepend-icon
                  append-icon="mdi-file-document"
                  accept=".docx, .xlsx, application/docx, application/xlsx"
                  small-chips
                  show-size
                  hide-details="auto"
                ></v-file-input>
              </v-col>
              <v-col cols="12">
                <label for>{{ $t("blankTemplate.status") }}</label>
                <v-autocomplete
                  clearable
                  v-model="form.is_active"
                  :items="[{text: 'Aktiv', value:1},{text: 'Noaktiv', value:0}]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">

              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="save">{{ $t("save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loading" width="300" hide-overlay>
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t('loadingText') }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  const axios = require("axios").default;
  import Swal from "sweetalert2";

  export default {
    data() {
      return {
        page: 1,
        from: 0,
        StaffDialog: false,
        server_items_length: -1,
        dataTableOptions: {page: 1, itemsPerPage: 50},
        dataTableValue: [],
        loading: false,
        items: [],
        formData: [],
        form: {},
        file: {},
        blankDialog: false,
      };
    },
    computed: {
      screenHeight() {
        return window.innerHeight - 170;
      },
      headers() {
        return [
          {text: "#", value: "id", align: "center", width: 30},
          {text: this.$t("blankTemplate.blank_name"), value: "blank_name"},
          {text: this.$t("documentTemplates.description"), value: "description"},
          {text: this.$t("blankTemplate.fileType"), value: "file_type"},
          {text: this.$t("blankTemplate.status"), value: "is_active"},

          {
            text: this.$t("actions"),
            value: "actions",
            width: 180,
            align: "center",
            padding: 0,
          }
        ];
      }
    },
    methods: {
      newBlank() {
        this.form = {
          id: Date.now(),
          blank_name: "",
          description: "",
          is_active: "",
        };
        this.blankDialog = true;
        if (this.$refs.blankDialog) this.$refs.blankDialog.reset();
      },
      updatePage($event) {
        this.getList();
      },
      updatePerPage($event) {
        this.getList();
      },
      downloadTemplate(file_name) {
        axios
          .get(this.$store.state.backend_url + "api/get-url/" + file_name)
          .then((res) => {
            let url = this.$store.state.backend_url + res.data;
            window.open(url);
            axios
              .post(
                this.$store.state.backend_url + "api/blank-templates/delete-file", {
                  file: res.data
                })
          })
      },
      editItem(item) {
        this.formIndex = this.items.indexOf(item);
        this.form = Object.assign({}, item);
        this.blankDialog = true;
        this.blankDialog.this.editMode = true;
      },
      deleteItem() {

      },
      save() {
        if (this.$refs.dialogForm.validate()) {
          this.loading = true;
          this.formData = new FormData();
          this.formData.append("id", this.form.id);
          this.formData.append("file", this.file);
          this.formData.append("file_type", this.form.file_type);
          this.formData.append("blank_name", this.form.blank_name);
          this.formData.append("description", this.form.description);
          this.formData.append("is_active", this.form.is_active);
          axios
            .post(this.$store.state.backend_url + "api/blank-templates/update",
              this.formData,
            )
            .then(() => {
              this.getList();
              this.blankDialog = false;
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              Toast.fire({
                icon: "success",
                title: this.$t("create_update_operation"),
              });
              this.loading = false;
            })
            .catch(function (error) {
              console.log(error);
              this.loading = false;
            });
        }
      },
      deleteItem(item) {
        const index = this.items.indexOf(item);
        Swal.fire({
          title: this.$t("swal_title"),
          text: this.$t("swal_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("swal_delete"),
        }).then((result) => {
          if (result.value) {
            axios
              .delete(
                this.$store.state.backend_url +
                "api/blank-templates/delete/" +
                item.id
              )
              .then((res) => {
                this.getList();
                this.dialog = false;
                Swal.fire("Deleted!", this.$t("swal_deleted"), "success");
              })
              .catch((err) => {
                Swal.fire({
                  icon: "error",
                  title: this.$t("swal_error_title"),
                  text: this.$t("swal_error_text"),
                  //footer: "<a href>Why do I have this issue?</a>"
                });
                console.log(err);
              });
          }
        });
      },
      getList() {
        this.loading = true;
        axios
          .post(this.$store.state.backend_url + "api/blank-templates", {
            pagination: this.dataTableOptions,
            search: this.search,
          })
          .then((response) => {
            this.items = response.data;
            this.server_items_length = response.data.total;
            this.from = response.data.from;
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
            this.loading = false;
          });

      },
    },
    mounted() {
      this.getList();
    }
  };
</script>
